import React from "react";
import { isChrome, isFirefox, isIeEdge, isSafari, getBrowser } from "@tightrope/lpscripts/browserdetect";
import Styles from "./css/chat.module.scss";
import Data from "./data/chat.json";

class chatgpt extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isChrome: isChrome(),
      isFirefox: isFirefox(),
      isIeEdge: isIeEdge(),
      isSafari: isSafari(),
      display: "none",
      pageData: props.data,
      data: Data,
      isLoading: true
    };
  }

  showBrowsers(browsers) {
    let display = false;
    if (getBrowser() === "ie" && browsers.includes("ie")) {
      display = true;
    }
    else if (getBrowser() === "firefox" && browsers.includes("firefox")) {
      display = true;
    }
   else if (getBrowser() === "chrome" && browsers.includes("chrome")) {
      display = true;
    }
   else if (getBrowser() === "edge" && browsers.includes("edge")) {
      display = true;
    }
   else if (getBrowser() === "safari" && browsers.includes("safari")) {
      display = true;
    }
   else if (getBrowser() === "other" && browsers.includes("other")) {
      display = true;
    }
    if (display) this.setState({ display: "block" });
    else this.setState({ display: "none" });
  }

  componentDidMount() {
    if (this.props.data) {
      this.props.data.then((data) => {
        const instanceData = Object.assign(this.state.data, data);
        this.setState({ data: instanceData });
        this.state.isChrome = isChrome();
        this.state.isFirefox = isFirefox();
        this.state.isIeEdge = isIeEdge();
        this.state.isSafari = isSafari();

        if (isChrome()) {
          const browserData = Object.assign(this.state.data, this.state.data.chrome);
          this.setState({ data: browserData });
        }
        if (isFirefox()) {
          const browserData = Object.assign(this.state.data, this.state.data.firefox);
          this.setState({ data: browserData });
        }
        if (isSafari()) {
          const browserData = Object.assign(this.state.data, this.state.data.safari);
          this.setState({ data: browserData });
        }
        if (isIeEdge()) {
          const browserData = Object.assign(this.state.data, this.state.data.edge);
          this.setState({ data: browserData });
        }
        if (this.props.browsers) {
          this.showBrowsers(this.props.browsers);
        } else {
          this.setState({ display: "block" });
        }
      });
    }

      //OS Detection
      document.querySelectorAll('.animCTA').forEach(p => {

          p.innerText = this.state.data.mainCTA;
  });
}


  render() {
    return (
      <>
        <div id={Styles.chatgpt}>
          <div className={Styles.atf}>

            <div className={Styles.header}>
              <img src={this.state.data.logo} alt="Manuals Library" />
              <p>{this.state.data.browser}</p>
            </div>

            <div className={Styles.content}>
              <h1 className="hc_param">{this.state.data.headline}</h1>
              <h2>{this.state.data.subhead}</h2>
              <button className={Styles.cta} data-cy="cta" onClick = {() => window.triggerInstall()}>{this.state.data.cta}</button>
              {this.props.children}
            </div>

            <div className={Styles.sliderWrap}>
              <div className={Styles.autoSliderContainer}>
                <ul className={Styles.autoSlider}>
                  <li>{this.state.data.item1}</li>
                  <li>{this.state.data.item2}</li>
                  <li>{this.state.data.item3}</li>
                  <li>{this.state.data.item4}</li>
                  <li>{this.state.data.item5}</li>
                </ul>
                <ul className={Styles.autoSlider}>
                  <li>{this.state.data.item1}</li>
                  <li>{this.state.data.item2}</li>
                  <li>{this.state.data.item3}</li>
                  <li>{this.state.data.item4}</li>
                  <li>{this.state.data.item5}</li>
                </ul>
                <ul className={Styles.autoSlider}>
                  <li>{this.state.data.item1}</li>
                  <li>{this.state.data.item2}</li>
                  <li>{this.state.data.item3}</li>
                  <li>{this.state.data.item4}</li>
                  <li>{this.state.data.item5}</li>
                </ul>
              </div>

              <div className={Styles.autoSliderContainer}>
                <ul className={Styles.autoSliderReverse}>
                  <li>{this.state.data.item6}</li>
                  <li>{this.state.data.item7}</li>
                  <li>{this.state.data.item8}</li>
                  <li>{this.state.data.item9}</li>
                  <li>{this.state.data.item10}</li>
                </ul>
                <ul className={Styles.autoSliderReverse}>
                  <li>{this.state.data.item6}</li>
                  <li>{this.state.data.item7}</li>
                  <li>{this.state.data.item8}</li>
                  <li>{this.state.data.item9}</li>
                  <li>{this.state.data.item10}</li>
                </ul>
                <ul className={Styles.autoSliderReverse}>
                  <li>{this.state.data.item6}</li>
                  <li>{this.state.data.item7}</li>
                  <li>{this.state.data.item8}</li>
                  <li>{this.state.data.item9}</li>
                  <li>{this.state.data.item10}</li>
                </ul>
              </div>
            </div>

            {this.props.footer}
          </div>
          </div>
          </>
    );
  }
}

export default chatgpt;
